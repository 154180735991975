import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {ControlContainer, FormsModule, NgForm} from '@angular/forms';
import {InvestmentRiskProfileComponent} from '@shared/analysis/asset-types/common/investment-risk-profile/investment-risk-profile.component';
import {ObjectiveAgeInputComponent} from '@shared/analysis/asset-types/common/objective-age-input/objective-age-input.component';
import {ObjectiveDescriptionComponent} from '@shared/analysis/asset-types/common/objective-description/objective-description.component';
import {FamilyMembersSelectComponent} from '@shared/analysis/components/family-members-select/family-members-select.component';
import {ChildBirthAsset} from '@shared/analysis/models/objectives';
import {FormModule, Slider2Component} from '@shared/ui';
import {FlexboxModule} from '@shared/ui/flexbox/flexbox.module';

@Component({
  standalone: true,
  selector: 'kpt-child-birth-form',
  templateUrl: './child-birth-form.component.html',
  imports: [
    FormsModule,
    CommonModule,
    FormModule,
    FlexboxModule,
    ObjectiveDescriptionComponent,
    ObjectiveAgeInputComponent,
    FamilyMembersSelectComponent,
    Slider2Component,
    InvestmentRiskProfileComponent,
  ],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class ChildBirthFormComponent {
  @Input() asset: ChildBirthAsset;

  translateSlider(value: number) {
    return `${value} měsíců`;
  }
}

import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {ControlContainer, FormsModule, NgForm} from '@angular/forms';
import {ObjectiveAgeInputComponent} from '@shared/analysis/asset-types/common/objective-age-input/objective-age-input.component';
import {ObjectiveDescriptionComponent} from '@shared/analysis/asset-types/common/objective-description/objective-description.component';
import {FamilyMembersSelectComponent} from '@shared/analysis/components/family-members-select/family-members-select.component';
import {RetirementInputMode} from '@shared/analysis/forms/definitions.models';
import {RetirementAsset} from '@shared/analysis/models/objectives';
import {FormModule} from '@shared/ui';
import {FlexboxModule} from '@shared/ui/flexbox/flexbox.module';

@Component({
  standalone: true,
  selector: 'kpt-retirement-form',
  templateUrl: './retirement-form.component.html',
  imports: [
    FormsModule,
    CommonModule,
    FormModule,
    ObjectiveDescriptionComponent,
    ObjectiveAgeInputComponent,
    FlexboxModule,
    FamilyMembersSelectComponent,
  ],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class RetirementFormComponent {
  @Input() asset: RetirementAsset;
  @Input() showPersonSelector = true;

  onValueChange() {
    this.asset.retirementInputMode = RetirementInputMode.Value;
    this.computeMonthlyRent();
  }

  onMonthlyRentChange() {
    this.asset.retirementInputMode = RetirementInputMode.Rent;
    this.computeValue();
  }

  onPensionDurationChange() {
    if (this.asset.retirementInputMode === RetirementInputMode.Rent) {
      this.computeValue();
    } else {
      this.computeMonthlyRent();
    }
  }

  private computeValue() {
    const {asset} = this;
    if (asset.monthlyRent && asset.pensionDuration) {
      asset.value = Math.round(asset.monthlyRent * asset.pensionDuration * 12);
    }
  }

  private computeMonthlyRent() {
    const {asset} = this;
    if (asset.value && asset.pensionDuration) {
      asset.monthlyRent = Math.round(asset.value / (asset.pensionDuration * 12));
    }
  }
}

export interface MaskOptions {
  mask: NumberConstructor | string;
  lazy?: boolean;
  blocks?: {[block: string]: MaskOptions};
}

export interface NumberMaskOptions extends MaskOptions {
  mask: NumberConstructor;
  thousandsSeparator?: string;
  scale?: number;
  signed?: boolean;
  padFractionalZeros?: boolean;
  min?: number;
  max?: number;
}

export interface PatternMaskOptions extends MaskOptions {
  mask: string;
  placeholderChar?: string;
}

export function numberMask(
  positive: boolean = false,
  decimals: number = 0,
  min?: number,
  max?: number,
): NumberMaskOptions {
  return {
    mask: Number,
    thousandsSeparator: ' ',
    scale: decimals,
    signed: !positive,
    min,
    max,
  };
}

export const phoneMask: PatternMaskOptions = {
  mask: '+{42PF} NR',
  blocks: {
    PF: numberMask(true, 0, 0, 1),
    NR: numberMask(true, 0, 0, 999_999_999),
  },
};

export const percentMask: NumberMaskOptions = {
  mask: Number,
  scale: 2,
  signed: false,
  padFractionalZeros: true,
};

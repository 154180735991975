import {NgModule} from '@angular/core';
import {AssetTypeService} from '@shared/analysis/asset-types/asset-type.service';
import {AssetFormComponent} from '@shared/analysis/asset-types/common/asset-form/asset-form.component';
import {CapitalLifeInsuranceAssetType} from '@shared/analysis/asset-types/insurance-products/capital-life-insurance/capital-life-insurance-asset-type';
import {InvestmentLifeInsuranceAssetType} from '@shared/analysis/asset-types/insurance-products/investment-life-insurance/investment-life-insurance-asset-type';
import {PropertyInsuranceAssetType} from '@shared/analysis/asset-types/insurance-products/property-insurance/property-insurance-asset-type';
import {RiskLifeInsuranceAssetType} from '@shared/analysis/asset-types/insurance-products/risk-life-insurance/risk-life-insurance-asset-type';
import {VehicleInsuranceAssetType} from '@shared/analysis/asset-types/insurance-products/vehicle-insurance/vehicle-insurance-asset-type';
import {BadDebtsRepaymentAssetType} from '@shared/analysis/asset-types/objectives/bad-debts-repayment/bad-debts-repayment-asset-type';
import {CarAssetType} from '@shared/analysis/asset-types/objectives/car/car-asset-type';
import {ChildBirthAssetType} from '@shared/analysis/asset-types/objectives/child-birth/child-birth-asset-type';
import {ChildrenFutureAssetType} from '@shared/analysis/asset-types/objectives/children-future/children-future-asset-type';
import {FamilyProvisionAssetType} from '@shared/analysis/asset-types/objectives/family-provision/family-provision-asset-type';
import {FinancialIndependenceAssetType} from '@shared/analysis/asset-types/objectives/financial-independence/financial-independence-asset-type';
import {FurnishingsAssetType} from '@shared/analysis/asset-types/objectives/furnishings/furnishings-asset-type';
import {LongTermReserveAssetType} from '@shared/analysis/asset-types/objectives/long-term-reserve/long-term-reserve-asset-type';
import {NewHousingAssetType} from '@shared/analysis/asset-types/objectives/new-housing/new-housing-asset-type';
import {OtherObjectiveAssetType} from '@shared/analysis/asset-types/objectives/other-objective/other-objective-asset-type';
import {OwnHousingAssetType} from '@shared/analysis/asset-types/objectives/own-housing/own-housing-asset-type';
import {PropertyProvisionAssetType} from '@shared/analysis/asset-types/objectives/property-provision/property-provision-asset-type';
import {PropertyRequirementsAssetType} from '@shared/analysis/asset-types/objectives/property-requirements/property-requirements-asset-type';
import {ReconstructionAssetType} from '@shared/analysis/asset-types/objectives/reconstruction/reconstruction-asset-type';
import {RetirementAssetType} from '@shared/analysis/asset-types/objectives/retirement/retirement-asset-type';
import {ShortTermReserveAssetType} from '@shared/analysis/asset-types/objectives/short-term-reserve/short-term-reserve-asset-type';
import {VacationAssetType} from '@shared/analysis/asset-types/objectives/vacation/vacation-asset-type';
import {VehicleProvisionAssetType} from '@shared/analysis/asset-types/objectives/vehicle-provision/vehicle-provision-asset-type';
import {VehicleRequirementsAssetType} from '@shared/analysis/asset-types/objectives/vehicle-requirements/vehicle-requirements-asset-type';
import {VehicleAssetType} from '@shared/analysis/asset-types/properties/vehicle/vehicle-asset-type';

@NgModule({
  declarations: [],
  imports: [AssetFormComponent],
  exports: [AssetFormComponent],
  providers: [
    AssetTypeService,
    PropertyProvisionAssetType,
    PropertyRequirementsAssetType,
    PropertyInsuranceAssetType,
    VehicleProvisionAssetType,
    VehicleRequirementsAssetType,
    VehicleInsuranceAssetType,
    VehicleAssetType,
    LongTermReserveAssetType,
    ShortTermReserveAssetType,
    FinancialIndependenceAssetType,
    ChildrenFutureAssetType,
    ChildBirthAssetType,
    BadDebtsRepaymentAssetType,
    NewHousingAssetType,
    ReconstructionAssetType,
    FurnishingsAssetType,
    CarAssetType,
    VacationAssetType,
    OtherObjectiveAssetType,
    OwnHousingAssetType,
    RetirementAssetType,
    FamilyProvisionAssetType,
    RiskLifeInsuranceAssetType,
    CapitalLifeInsuranceAssetType,
    InvestmentLifeInsuranceAssetType,
  ],
})
export class AssetTypesModule {}

import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {AssetHelperService} from '@shared/analysis/asset-helper.service';
import {
  selectAdultFamilyMembers,
  selectChildrenFamilyMembers,
  selectFamilyMembers,
} from '@shared/analysis/operators';
import {IOption, SelectModule} from '@shared/ui';
import {CommonFieldStatusesModule} from '@shared/ui/forms/common-field/common-field-statuses.module';
import {RadioGroupModule} from '@shared/ui/forms/radio-group/radio-group.module';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {State} from 'src/store';

export enum FamilyMembersInputType {
  Radio = 'radio',
  Checkbox = 'checkbox',
  Select = 'select',
}

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'kpt-family-members-select',
  templateUrl: './family-members-select.component.html',
  imports: [CommonModule, CommonFieldStatusesModule, RadioGroupModule, FormsModule, SelectModule],
})
export class FamilyMembersSelectComponent implements OnInit {
  @Input() type: FamilyMembersInputType = FamilyMembersInputType.Radio;
  @Input() label: string;
  @Input() onlyAdults = false;
  @Input() onlyChildren = false;
  @Input() required = false;
  @Input() disabled = false;

  @Input() value: string;
  @Output() valueChange = new EventEmitter<string>();

  options$: Observable<IOption[]>;

  FamilyMembersInputType = FamilyMembersInputType;

  constructor(private store: Store<State>, private assetHelperService: AssetHelperService) {}

  ngOnInit() {
    const familyMembers$ = this.store.pipe(
      this.onlyAdults
        ? selectAdultFamilyMembers()
        : this.onlyChildren
        ? selectChildrenFamilyMembers()
        : selectFamilyMembers(),
    );

    this.options$ = familyMembers$.pipe(
      map((familyMembers: FamilyMember[]) =>
        familyMembers.map(
          familyMember =>
            ({
              label: this.assetHelperService.formatFamilyMemberCaption(familyMember),
              key: familyMember.sugarUuid,
            } as IOption),
        ),
      ),
      untilDestroyed(this),
    );
  }
}

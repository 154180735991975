/* tslint:disable:max-line-length */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * 127.0.0.1:8000/api
 */

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

export interface BuildingParams {
  /** the loan which the client needs in CZK */
  loan: number;
  /** if true, all numeric data about the credit are displayed */
  fullinfo?: boolean;
  /** the length of the fixation of the interest rate in years */
  fixation?: number;
  /** the value of the estate in CZK, (if omitted, the backend assumes value=loan) */
  value?: number;
  /** the length of the payments in months */
  loan_term: number;
  /** accept values: `house` - standard mortgage, `building` - loan from building savings, `refinancing` – refinancing mortgage, `american` - american mortgage */
  purposes: PurposesBuildingParamsEnum[];
}

export type PurposesBuildingParamsEnum = 'house' | 'building' | 'refinancing' | 'american';

export interface DetailParams {
  /** the loan which the client needs in CZK */
  loan: number;
  /** the length of the payments in months */
  loan_term: number;
  /** the interest rate of the consumer credit in per cent */
  interest: number;
  /** the code of the credit product name */
  product_code: string;
  /** the length of the fixation of the interest rate in years */
  fixation: number;
}

@Injectable()
export class BuildingService {
  constructor(private http: HttpClient) {}

  /**
   * List of building credits and their interest rates
   * Returns the list of banks and interest rates which are offered by banks for the specified query parameters.
   * http://127.0.0.1:8000/swagger/swagger-ui.html#!/building/calculator_building_list
   */
  building(params: BuildingParams): Observable<__model.CalculateMortgage[]> {
    const queryParamBase = {
      loan: params.loan,
      fullinfo: params.fullinfo,
      fixation: params.fixation,
      value: params.value,
      loan_term: params.loan_term,
      purposes: params.purposes,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value))
          value.forEach(v => (queryParams = queryParams.append(key, v)));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.CalculateMortgage[]>(`/api/calculator/building/`, {
      params: queryParams,
    });
  }

  /**
   * Building credit detail
   * Returns the complete numeric information about the mortgage specified by query parameters.
   * http://127.0.0.1:8000/swagger/swagger-ui.html#!/building/calculator_building_detail_list
   */
  detail(params: DetailParams): Observable<__model.GetMortgage> {
    const queryParamBase = {
      loan: params.loan,
      loan_term: params.loan_term,
      interest: params.interest,
      product_code: params.product_code,
      fixation: params.fixation,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value))
          value.forEach(v => (queryParams = queryParams.append(key, v)));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.GetMortgage>(`/api/calculator/building/detail/`, {
      params: queryParams,
    });
  }
}

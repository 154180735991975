/* tslint:disable:max-line-length */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * 127.0.0.1:8000/api
 */

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

export interface ConsumerParams {
  /** the loan which the client needs in CZK */
  loan: number;
  /** if true, all numeric data about the credit are displayed */
  fullinfo?: boolean;
  /** the length of the payments in months */
  loan_term?: number;
}

export interface DetailParams {
  /** the loan which the client needs in CZK */
  loan: number;
  /** the length of the payments in months */
  loan_term: number;
  /** the interest rate of the consumer credit in per cent */
  interest: number;
  /** the code of the credit product name */
  product_code: string;
}

@Injectable()
export class ConsumerService {
  constructor(private http: HttpClient) {}

  /**
   * List of consumer credits and their interest rates
   * This method returns the list of banks and interest rates of consumer credits, which are offered by banks for the specified query parameters.
   * http://127.0.0.1:8000/swagger/swagger-ui.html#!/consumer/calculator_consumer_list
   */
  consumer(params: ConsumerParams): Observable<__model.CalculateConsumer[]> {
    const queryParamBase = {
      loan: params.loan,
      fullinfo: params.fullinfo,
      loan_term: params.loan_term,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value))
          value.forEach(v => (queryParams = queryParams.append(key, v)));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.CalculateConsumer[]>(`/api/calculator/consumer/`, {
      params: queryParams,
    });
  }

  /**
   * Consumer credit detail
   * This method returns the complete numeric information about the mortgage specified by query parameters.
   * http://127.0.0.1:8000/swagger/swagger-ui.html#!/consumer/calculator_consumer_detail_list
   */
  detail(params: DetailParams): Observable<__model.GetConsumer> {
    const queryParamBase = {
      loan: params.loan,
      loan_term: params.loan_term,
      interest: params.interest,
      product_code: params.product_code,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]: [string, any]) => {
      if (value !== undefined) {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value))
          value.forEach(v => (queryParams = queryParams.append(key, v)));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      }
    });

    return this.http.get<__model.GetConsumer>(`/api/calculator/consumer/detail/`, {
      params: queryParams,
    });
  }
}

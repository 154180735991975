import {Injectable} from '@angular/core';
import {IntegrationsService} from '@generated/controllers/Integrations';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {VehicleObject} from '@generated/defs/VehicleObject';
import {Store} from '@ngrx/store';
import {AssetTypeService} from '@shared/analysis/asset-types/asset-type.service';
import {AssetsHandlerService} from '@shared/analysis/assets-handler.service';
import {Asset, AssetType, RelatedPropertyAsset} from '@shared/analysis/models/asset';
import {VehicleAsset} from '@shared/analysis/models/movable-properties';
import {selectFamilyMembers} from '@shared/analysis/operators';
import {selectAllAssets} from '@shared/analysis/store';
import {RemoteLoggerService} from '@shared/services/remote-logger.service';
import {cloneDeep, isEqual} from 'lodash';
import * as moment from 'moment';
import {take} from 'rxjs/operators';
import {FeatureFlagsService} from 'src/app/services/feature-flags.service';
import {State} from 'src/store';

@Injectable()
export class CoreSyncPropertiesService {
  constructor(
    private store: Store<State>,
    private assetTypeService: AssetTypeService,
    private assetsHandlerService: AssetsHandlerService,
    private integrationsService: IntegrationsService,
    private featureFlagsService: FeatureFlagsService,
    private remoteLoggerService: RemoteLoggerService,
  ) {}

  async sync() {
    if (!this.featureFlagsService.showNewDashboard) {
      return;
    }

    const familyMembers = await this.store.pipe(selectFamilyMembers(), take(1)).toPromise();
    for (const familyMember of familyMembers) {
      await this.syncForFamilyMember(familyMember);
    }
  }

  private async syncForFamilyMember(familyMember: FamilyMember) {
    this.info('Syncing core properties for family member', familyMember.sugarUuid);

    const objects = await this.integrationsService
      .objects({client_uuid: familyMember.sugarUuid})
      .toPromise();

    const allAssets = await this.store.select(selectAllAssets).pipe(take(1)).toPromise();
    const coreAssets = new Map<string, Asset>();
    for (const asset of allAssets) {
      if ('coreUuid' in asset) {
        coreAssets.set(asset.coreUuid, asset);
      }
    }

    for (const object of objects) {
      try {
        let propertyAsset = cloneDeep(coreAssets.get(object.guid));
        const prevPropertyAsset = cloneDeep(propertyAsset);

        if (object.vehicle) {
          propertyAsset = await this.createOrUpdateVehicleFromCore(
            propertyAsset,
            object.vehicle,
            familyMember,
          );
        } else {
          this.warn('Core property type not supported, skipping sync', object.type, object.guid);
          continue;
        }

        if (!isEqual(propertyAsset, prevPropertyAsset)) {
          this.info('Save asset', JSON.stringify(propertyAsset));
          await this.assetsHandlerService.saveAsset(propertyAsset);
        } else {
          this.info('No need to save unchanged asset', propertyAsset.assetUuid);
        }

        const contractIds = object.policiesCollection.map(p => p.guid);
        for (const contractId of contractIds) {
          const contractAsset = cloneDeep(coreAssets.get(contractId));
          if (!contractAsset) continue;
          if (
            (contractAsset as RelatedPropertyAsset).relatedPropertyUuid === propertyAsset.assetUuid
          ) {
            continue;
          }

          this.info('Syncing related property', contractAsset.assetUuid, propertyAsset.assetUuid);
          (contractAsset as RelatedPropertyAsset).relatedPropertyUuid = propertyAsset.assetUuid;
          await this.assetsHandlerService.saveAsset(contractAsset);
        }
      } catch (err) {
        // TODO: will Sentry log this?
        this.error('Failed to sync core asset', object.guid, err);
      }
    }
  }

  private async createOrUpdateVehicleFromCore(
    propertyAsset: Asset,
    coreObj: VehicleObject,
    familyMember: FamilyMember,
  ): Promise<VehicleAsset> {
    if (!propertyAsset) {
      propertyAsset = await this.assetTypeService.create(AssetType.Vehicle);
    }

    if (propertyAsset.type !== AssetType.Vehicle) {
      throw new Error(`Expected vehicle, got ${propertyAsset.type}`);
    }

    propertyAsset.coreUuid = coreObj.guid;
    propertyAsset.ownerUuid = familyMember.sugarUuid;
    propertyAsset.model = coreObj.model;
    propertyAsset.seats = coreObj.seats;
    propertyAsset.weight = coreObj.weight;
    propertyAsset.mileage = coreObj.mileage;
    propertyAsset.category = coreObj.category;
    propertyAsset.fuelType = coreObj.fuelType;
    propertyAsset.enginePower = coreObj.enginePower;
    propertyAsset.manufacturer = coreObj.manufacturer;
    propertyAsset.value = coreObj.vehicleValue;
    propertyAsset.engineCapacity = coreObj.engineCapacity;
    propertyAsset.annualMileage = coreObj.mileageAnnualy;
    propertyAsset.yearBeginningUse = moment(coreObj.manufactureDate, 'YYYY-MM-DD', true).year();
    propertyAsset.registrationPlate = coreObj.registrationPlate;
    propertyAsset.vehicleRegistrationCertificate = coreObj.registrationBookNumber;
    propertyAsset.vehicleIdentificationNumber = coreObj.vehicleIdentificationNumber;
    // default name is not provided by the core, construct it from the manufacturer and model
    propertyAsset.name =
      propertyAsset.name || `${propertyAsset.manufacturer} ${propertyAsset.model}`;

    return propertyAsset;
  }

  private info(...data: unknown[]) {
    this.remoteLoggerService.info('Core Sync Properties', ...data);
  }

  private warn(...data: unknown[]) {
    this.remoteLoggerService.warn('Core Sync Properties', ...data);
  }

  private error(...data: unknown[]) {
    this.remoteLoggerService.error('Core Sync Properties', ...data);
  }
}

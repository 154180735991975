import {Injectable} from '@angular/core';
import {col, column, row} from '@lib/utils';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {CapitalLifeInsuranceAssetType} from '@shared/analysis/asset-types/insurance-products/capital-life-insurance/capital-life-insurance-asset-type';
import {InvestmentLifeInsuranceAssetType} from '@shared/analysis/asset-types/insurance-products/investment-life-insurance/investment-life-insurance-asset-type';
import {PropertyInsuranceAssetType} from '@shared/analysis/asset-types/insurance-products/property-insurance/property-insurance-asset-type';
import {RiskLifeInsuranceAssetType} from '@shared/analysis/asset-types/insurance-products/risk-life-insurance/risk-life-insurance-asset-type';
import {VehicleInsuranceAssetType} from '@shared/analysis/asset-types/insurance-products/vehicle-insurance/vehicle-insurance-asset-type';
import {FieldLabel} from '@shared/analysis/field-label';
import {FormlyTypes} from '@shared/ui/formly/formly.enums';
import {
  createNamedGroup,
  createTabs,
  getBasicField,
  getSelectField,
  getSliderPercentField,
} from '@shared/ui/formly/formly.utils';
import {assetNames, AssetType} from '../models/asset';
import {
  getOptionsFromNames,
  liabilityInsuranceRegionNames,
  liabilityInsuranceRiskGroupNames,
  liabilityInsuranceScopeNames,
  participationTypeNames,
} from '../models/assets.enums';
import {getAssetValidators} from '../validations.helpers';
import {AbstractAssetsDefinitions} from './abstract-assets.definitions';
import {AssetDefinition, FinancialProductTab} from './definitions.models';
import {createProductTab} from './forms.helpers';

@Injectable()
export class InsuranceProducts {
  constructor(
    private abstractAssets: AbstractAssetsDefinitions,
    private propertyInsurance: PropertyInsuranceAssetType,
    private vehicleInsurance: VehicleInsuranceAssetType,
    private capitalLifeInsurance: CapitalLifeInsuranceAssetType,
    private investmentLifeInsurance: InvestmentLifeInsuranceAssetType,
    private riskLifeInsurance: RiskLifeInsuranceAssetType,
  ) {}

  liabilityInsurance(): AssetDefinition {
    const type = AssetType.LiabilityInsurance;
    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getProductGroup(type),
        this.abstractAssets.getCommonFinancialProductGroup('contractName'),
        createNamedGroup('Osoby', this.abstractAssets.getStakeholderField()),
        this.abstractAssets.getCommonFinancialProductGroup('payment'),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
      ),
      createProductTab(
        FinancialProductTab.Extra,
        createNamedGroup(
          'Detaily smlouvy',
          row([
            col(getBasicField(FormlyTypes.Text, 'profession', FieldLabel.PROFESSION)),
            col(
              getSelectField(
                'riskGroup',
                FieldLabel.RISK_GROUP,
                getOptionsFromNames(liabilityInsuranceRiskGroupNames),
              ),
            ),
          ]),
          row([
            col(
              getSelectField(
                'scope',
                FieldLabel.INSURANCE_SCOPE,
                getOptionsFromNames(liabilityInsuranceScopeNames),
              ),
            ),
            col(
              getSelectField(
                'region',
                FieldLabel.REGION,
                getOptionsFromNames(liabilityInsuranceRegionNames),
              ),
            ),
          ]),
          row([col(getBasicField(FormlyTypes.Currency, 'limit', FieldLabel.INSURANCE_LIMIT))]),
          ...this.commonInsuranceParticipationFields(),
        ),
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),
    );

    return {
      name: assetNames[type],
      type,
      fields: [tabs],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  travelInsurance(): AssetDefinition {
    const type = AssetType.TravelInsurance;
    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getProductGroup(type),
        this.abstractAssets.getCommonFinancialProductGroup('contractName'),
        createNamedGroup(
          'Osoby',
          this.abstractAssets.getStakeholderField(),
          this.abstractAssets.insuredPersonUuidsField(true),
        ),
        this.abstractAssets.getCommonFinancialProductGroup('payment'),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
      ),

      createProductTab(
        FinancialProductTab.Extra,
        createNamedGroup(
          'Detaily smlouvy',
          row([
            col(
              getBasicField(
                FormlyTypes.Currency,
                'medicalExpensesInsurance',
                FieldLabel.MEDICAL_EXPENSES_INSURANCE,
              ),
            ),
            col(getBasicField(FormlyTypes.Currency, 'insuranceUzo', FieldLabel.INSURANCE_UZO)),
          ]),
          row([
            col(getBasicField(FormlyTypes.Text, 'territorialScope', FieldLabel.TERRITORIAL_SCOPE)),
          ]),
          row([
            column(
              [getBasicField(FormlyTypes.Checkbox, 'riskSports', FieldLabel.RISK_SPORTS)],
              [4, 4],
            ),
            column(
              [
                getBasicField(
                  FormlyTypes.Text,
                  'riskSportsSpecification',
                  FieldLabel.RISK_SPORTS_SPECIFICATION,
                  null,
                  {'templateOptions.disabled': 'model.riskSports === false'},
                ),
              ],
              [8, 8],
            ),
          ]),
          this.abstractAssets.otherRisks,
        ),
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),
    );

    return {
      name: assetNames[type],
      type,
      fields: [tabs],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  businessInsurance(): AssetDefinition {
    const type = AssetType.BusinessInsurance;
    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getProductGroup(type),
        this.abstractAssets.getCommonFinancialProductGroup('contractName'),
        createNamedGroup('Osoby', this.abstractAssets.getStakeholderField()),
        this.abstractAssets.getCommonFinancialProductGroup('payment'),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
      ),
      createProductTab(
        FinancialProductTab.Extra,
        ...this.commonInsuranceParticipationFields(),
        this.abstractAssets.otherRisks,
      ),
      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),
    );

    return {
      name: assetNames[type],
      type,
      fields: [tabs],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  getAll() {
    return [
      this.investmentLifeInsurance.getAssetDefinition(),
      this.riskLifeInsurance.getAssetDefinition(),
      this.capitalLifeInsurance.getAssetDefinition(),
      this.propertyInsurance.getAssetDefinition(),
      this.liabilityInsurance(),
      this.vehicleInsurance.getAssetDefinition(),
      this.travelInsurance(),
      this.businessInsurance(),
    ];
  }

  getAllForProperties() {
    return [
      this.investmentLifeInsurance.getAssetDefinition(),
      this.capitalLifeInsurance.getAssetDefinition(),
    ];
  }

  commonInsuranceParticipationFields(): FormlyFieldConfig[] {
    return [
      row([
        column(
          [getSliderPercentField('percentParticipation', FieldLabel.PARTICIPATION, 1)],
          [6, 6],
        ),
        column(
          [
            getSelectField(
              'participationType',
              FieldLabel.PARTICIPATION_TYPE,
              getOptionsFromNames(participationTypeNames),
            ),
          ],
          [2, 2],
        ),
        column(
          [
            getBasicField(
              FormlyTypes.Currency,
              'participationValue',
              FieldLabel.PARTICIPATION_VALUE,
            ),
          ],
          [4, 4],
        ),
      ]),
    ];
  }
}

import {CommonModule} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, FormsModule, NgForm} from '@angular/forms';
import {IntegrationsService} from '@generated/controllers/Integrations';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {FamilyMembersSelectComponent} from '@shared/analysis/components/family-members-select/family-members-select.component';
import {VehicleAsset} from '@shared/analysis/models/movable-properties';
import {ButtonModule, DatepickerModule, FormModule} from '@shared/ui';
import {FlexboxModule} from '@shared/ui/flexbox/flexbox.module';
import {SwitchModule} from '@shared/ui/forms/switch';
import {isEqual} from 'lodash';
import * as moment from 'moment/moment';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, shareReplay, switchMap} from 'rxjs/operators';

const VEHICLE_CATEGORIES = [
  'Osobní',
  'Motocykl',
  'Terénní',
  'Užitkové',
  'Přívěsy',
  'Návěsy nákladní',
  'Tříkolka, čtyřkolka',
];

const VEHICLE_FUEL_TYPES = ['Benzín', 'Nafta', 'LNG', 'Elektřina'];

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'kpt-vehicle-form',
  templateUrl: './vehicle-form.component.html',
  imports: [
    FormsModule,
    CommonModule,
    FormModule,
    ButtonModule,
    SwitchModule,
    FlexboxModule,
    DatepickerModule,
    FamilyMembersSelectComponent,
  ],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class VehicleFormComponent implements OnInit {
  @Input()
  asset: VehicleAsset;

  loading = false;
  error = '';

  manufacturer$ = new BehaviorSubject('');
  model$ = new BehaviorSubject('');

  manufacturers$ = this.integrationsService.listVehicleManufacturers().pipe(
    map(manufacturers =>
      manufacturers
        .filter(m => !!m) // portal sends empty value in the list, filter it out
        .sort(new Intl.Collator('cs').compare),
    ),
    shareReplay(1),
  );

  manufacturersAutocomplete$ = combineLatest([this.manufacturers$, this.manufacturer$]).pipe(
    map(([manufacturers, value]) => {
      return manufacturers.filter(m => !value || this.normalize(m).includes(this.normalize(value)));
    }),
    untilDestroyed(this),
  );

  modelAutocomplete$ = combineLatest([this.manufacturer$, this.model$]).pipe(
    debounceTime(400),
    distinctUntilChanged(isEqual),
    switchMap(([manufacturer, model]) => {
      if (!manufacturer || manufacturer.length < 3) return of([]);

      return this.listVehicleModels(manufacturer).pipe(
        map(models => {
          models = models.filter(m => !model || this.normalize(m).includes(this.normalize(model)));
          return models;
        }),
      );
    }),
    untilDestroyed(this),
  );

  categoryAutocomplete$ = of(VEHICLE_CATEGORIES);
  fuelTypeAutocomplete$ = of(VEHICLE_FUEL_TYPES);

  // cache of models available for each manufacturer
  private modelCache = new Map<string, Observable<string[]>>();

  constructor(private integrationsService: IntegrationsService) {}

  ngOnInit() {
    this.manufacturer$.next(this.asset?.manufacturer);
    this.model$.next(this.asset?.manufacturer);
  }

  async loadVehicleInfo() {
    try {
      this.loading = true;
      this.error = '';

      const rpOrVin = this.asset.registrationPlate || this.asset.vehicleIdentificationNumber;
      const vehicle = await this.integrationsService.findVehicle({rpOrVin}).toPromise();
      if (!vehicle) {
        this.error = 'Vozidlo nebylo nalezeno';
        return;
      }

      Object.assign(this.asset, {
        registrationPlate: vehicle.registrationPlate,
        vehicleIdentificationNumber: vehicle.vehicleIdentificationNumber,
        manufacturer: vehicle.manufacturer,
        model: vehicle.model,
        category: vehicle.category,
        vehicleRegistrationCertificate: vehicle.registrationBookNumber,
        yearBeginningUse: moment(vehicle.manufactureDate).get('year'),
        mileage: vehicle.mileage,
        annualMileage: vehicle.mileageAnnualy,
        leasing: vehicle.isLeased,
        fuelType: vehicle.fuelType,
        seats: vehicle.seats,
        engineCapacity: vehicle.engineCapacity,
        enginePower: vehicle.enginePower,
        weight: vehicle.weight,
      });

      if (!this.asset.name) {
        this.asset.name = [vehicle.manufacturer, vehicle.model].join(' ');
      }
      if (!this.asset.value && vehicle.vehicleValue) {
        this.asset.value = vehicle.vehicleValue;
      }
    } catch (err) {
      console.error('Error while loading the vehicle info', err);
      this.error = 'Chyba při hledání vozidla';
    } finally {
      this.loading = false;
    }
  }

  private listVehicleModels(manufacturer: string) {
    if (this.modelCache.has(manufacturer)) {
      return this.modelCache.get(manufacturer);
    }

    return this.integrationsService.listVehicleModels({manufacturer}).pipe(
      map(models => {
        models = models.sort(new Intl.Collator('cs').compare);
        this.modelCache.set(manufacturer, of(models));
        return models;
      }),
    );
  }

  /**
   * Normalizes string for search. Removes diacritics and converts to lowercase.
   *
   * @param str
   */
  private normalize(str: string) {
    return str
      ?.normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }
}

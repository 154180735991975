import {DocumentTypeFileEnum} from '@generated/model';
import {
  BaseAsset,
  CoreAsset,
  DateAsset,
  NameAsset,
  RegularPaymentType,
  SugarAsset,
  ValueAsset,
} from '@shared/analysis/models/asset';
import {CreditAsset} from '@shared/analysis/models/credit-products';
import {InsuranceAsset} from '@shared/analysis/models/insurance-products';
import {InvestmentAsset} from '@shared/analysis/models/investment-products';

export interface CommonFinancialProductAsset
  extends BaseAsset,
    SugarAsset,
    CoreAsset,
    ValueAsset,
    DateAsset,
    NameAsset,
    StakeholderAsset,
    PaymentInformationAsset {
  // Tab Název smlouvy
  // tab has `name` field with label 'Název smlouvy'
  /** Číslo smlouvy */
  contractNumber: string;

  // Tab Produkt
  /** Cizí smlouva? */
  foreignContract: boolean;
  /** Core status */
  coreStatus: string;
  /** Core secondary status */
  coreSecondaryStatus: string;
  /** Partner */
  partnerId: string;
  /** Produkt */
  productId: string;

  // Group Období
  // tab has `start` field with label 'Sjednáno od'
  // tab has `end` field with label 'Sjednáno do'

  // Tab Platba
  // tab has `value` field with label 'Platba'
  /** Frekvence placení */
  frequency: RegularPaymentType;

  // Group Poznámky a přílohy
  /** Cíl */
  goal: string;
  // tab has `note` field with label 'Poznámky'
  /** Přílohy */
  attachments: FinancialProductAttachment[];
}

export interface FinancialProductAttachment {
  dmsUuid: string;
  fileName: string;
  documentType: DocumentTypeFileEnum;
}

export interface StakeholderAsset {
  // Tab Osoby
  /** Pojistník; adults only */
  stakeholderUuid: string;
}

export function isStakeholderAsset(asset: any): asset is StakeholderAsset {
  return asset.hasOwnProperty('stakeholderUuid');
}

export interface PaymentInformationAsset {
  // Group Platební údaje
  /** Číslo účtu */
  bankAccountNumber: string;
  /** Variabilní symbol */
  variableSymbol: string;
  /** Den platby v rámci období */
  paymentDay: number;
  /** Datum první platby */
  paymentStart: string;
  /** Datum poslední platby */
  paymentEnd: string;
  /** Poznámka k platbě */
  paymentNote: string;
}

export type FinancialProductsAsset = InsuranceAsset | InvestmentAsset | CreditAsset;

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {GetPriorityQuestionnaireModule} from '@generated/store/lifeinsurance/getPriorityQuestionnaire/getPriorityQuestionnaire.module';
import {AssetFormComponent} from '@shared/analysis/asset-types/common/asset-form/asset-form.component';
import {FamilyProvisionSummaryComponent} from '@shared/analysis/asset-types/objectives/family-provision/family-provision-summary.component';
import {InvestmentSurveySummaryModule} from '@shared/analysis/components/investment-survey-summary/investment-survey-summary.module';
import {RisksSelectionModule} from '@shared/analysis/components/risks-selection/risks-selection.module';
import {CommonBaseModule} from '@shared/lib';
import {ButtonModule} from '@shared/ui';
import {InputModule} from '@shared/ui/forms/input/input.module';
import {ProposalsModule} from 'src/app/modules/life-insurance-old/5-proposals/proposals.module';
import {CategoryTypeComponent} from './components/category-type/category-type.component';
import {InvestmentSurveySummaryComponent} from './components/investment-survey-summary/investment-survey-summary.component';
import {ItemComponent} from './components/item/item.component';
import {ItemsGroupComponent} from './components/items-group/items-group.component';
import {ReadonlyRisksSelectionComponent} from './components/readonly-risks-selection/readonly-risks-selection.component';
import {SectionComponent} from './components/section/section.component';
import {SectionCategoryComponent} from './components/section-category/section-category.component';
import {SummaryPrioritiesResultsComponent} from './components/summary-priorities-results/summary-priorities-results.component';
import {SummaryComponent} from './summary.component';

@NgModule({
  declarations: [
    SummaryComponent,
    SectionComponent,
    ItemComponent,
    SectionCategoryComponent,
    CategoryTypeComponent,
    ItemsGroupComponent,
    ReadonlyRisksSelectionComponent,
    SummaryPrioritiesResultsComponent,
    InvestmentSurveySummaryComponent,
  ],
  exports: [SummaryComponent],
  imports: [
    CommonModule,
    CommonBaseModule,
    RisksSelectionModule,
    ProposalsModule,
    GetPriorityQuestionnaireModule,
    ButtonModule,
    InvestmentSurveySummaryModule,
    ReactiveFormsModule,
    InputModule,
    AssetFormComponent,
    FamilyProvisionSummaryComponent,
  ],
})
export class SummaryModule {}

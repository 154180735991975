/* tslint:disable:max-line-length */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * 127.0.0.1:8000/api
 */

import {Injectable} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormArrayExtended} from '../../../common/formArrayExtended';
import {IntegrationsService} from '../../../controllers/Integrations';

@Injectable()
export class DeleteFilesFormService {
  form: UntypedFormGroup;
  constructor(private integrationsService: IntegrationsService) {
    this.form = new UntypedFormGroup({
      data: new FormArrayExtended(
        () =>
          new UntypedFormGroup(
            {
              dmsUuid: new UntypedFormControl(undefined, [Validators.required]),
            },
            [Validators.required],
          ),
        [],
        [Validators.required],
      ),
    });
  }

  submit(raw = false) {
    const data = raw ? this.form.getRawValue() : this.form.value;
    return this.integrationsService.deleteFiles(data);
  }
}

/* tslint:disable:max-line-length */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * 127.0.0.1:8000/api
 */

import {Injectable} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {IntegrationsService} from '../../../controllers/Integrations';

@Injectable()
export class GetClientFormService {
  form: UntypedFormGroup;
  constructor(private integrationsService: IntegrationsService) {
    this.form = new UntypedFormGroup({
      sugar_uuid: new UntypedFormControl(undefined, [Validators.required]),
    });
  }

  submit(raw = false) {
    const data = raw ? this.form.getRawValue() : this.form.value;
    return this.integrationsService.getClient(data);
  }
}

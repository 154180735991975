import {Injectable} from '@angular/core';
import {BaseAssetType} from '@shared/analysis/asset-types/common/base-asset-type';
import {FinancialProductAssetType} from '@shared/analysis/asset-types/common/financial-product-asset-type';
import {AbstractAssetsDefinitions} from '@shared/analysis/forms/abstract-assets.definitions';
import {AssetDefinition, FinancialProductTab} from '@shared/analysis/forms/definitions.models';
import {createProductTab} from '@shared/analysis/forms/forms.helpers';
import {assetNames, AssetType} from '@shared/analysis/models/asset';
import {
  LifeInsuranceAsset,
  RiskLifeInsuranceAsset,
} from '@shared/analysis/models/insurance-products';
import {getAssetValidators} from '@shared/analysis/validations.helpers';
import {createFulfillmentField, createNamedGroup, createTabs} from '@shared/ui/formly/formly.utils';

@Injectable()
export class RiskLifeInsuranceAssetType extends BaseAssetType {
  constructor(
    private abstractAssets: AbstractAssetsDefinitions,
    private financialProductAssetType: FinancialProductAssetType,
  ) {
    super();
  }

  async create(
    opts: Pick<RiskLifeInsuranceAsset, 'proposal'>,
  ): Promise<Partial<RiskLifeInsuranceAsset>> {
    const asset: Partial<RiskLifeInsuranceAsset> = {
      assetUuid: this.createId(),
      ...this.financialProductAssetType.getLifeInsurance(),
      proposal: opts.proposal,
      type: AssetType.RiskLifeInsurance,
    };

    // There are three ways how RiskLifeInsuranceAsset can be created:
    //
    // 1. Proposal is created
    // 2. Existing contract is created
    // 3. Existing contract is synchronized from Core
    //
    // If 1, then prefill the contract limits as specified in the family provision asset
    if (opts.proposal) {
      await this.financialProductAssetType.copyRisksFromFamilyProvision(
        asset as LifeInsuranceAsset,
      );
    }

    return asset;
  }

  getAssetDefinition(): AssetDefinition {
    const type = AssetType.RiskLifeInsurance;
    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getProductGroup(type),
        this.abstractAssets.getCommonFinancialProductGroup('contractName'),
        this.abstractAssets.getRelatedAndStakeholderField(type),
        this.abstractAssets.getCommonFinancialProductGroup('payment'),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
        this.abstractAssets.getJustificationGroup(),
      ),

      createProductTab(
        FinancialProductTab.Extra,
        createNamedGroup('Osoby', this.abstractAssets.lifeInsuranceAsset().insuredPersons),
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),

      this.abstractAssets.getCoreUuidField(),
    );

    return {
      name: assetNames[type],
      type,
      fields: [tabs, createFulfillmentField()],
      validators: getAssetValidators(type),
      model: {},
    };
  }
}

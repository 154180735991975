/* tslint:disable:max-line-length */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * 127.0.0.1:8000/api
 */

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as __model from '../model';

export interface CreateOrUpdateAdvisorUiSettingsParams {
  data: __model.UiSettingsAdvisor;
}

export interface GetUiSettingsParams {
  family_uuid: string;
}

export interface CreateOrUpdateUiSettingsParams {
  data: __model.UiSettings;
  family_uuid: string;
}

@Injectable()
export class UiService {
  constructor(private http: HttpClient) {}

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/ui/getAdvisorUiSettings */
  getAdvisorUiSettings(): Observable<__model.UiSettingsAdvisor> {
    return this.http.get<__model.UiSettingsAdvisor>(`/api/ui/settings/`);
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/ui/createOrUpdateAdvisorUiSettings */
  createOrUpdateAdvisorUiSettings(
    params: CreateOrUpdateAdvisorUiSettingsParams,
  ): Observable<__model.UiSettingsAdvisor> {
    const bodyParams = params.data;

    return this.http.post<__model.UiSettingsAdvisor>(`/api/ui/settings/`, bodyParams || {});
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/ui/getUiSettings */
  getUiSettings(params: GetUiSettingsParams): Observable<__model.UiSettings> {
    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.get<__model.UiSettings>(`/api/ui/settings/${pathParams.family_uuid}/`);
  }

  /** http://127.0.0.1:8000/swagger/swagger-ui.html#!/ui/createOrUpdateUiSettings */
  createOrUpdateUiSettings(params: CreateOrUpdateUiSettingsParams): Observable<__model.UiSettings> {
    const bodyParams = params.data;

    const pathParams = {
      family_uuid: params.family_uuid,
    };
    return this.http.post<__model.UiSettings>(
      `/api/ui/settings/${pathParams.family_uuid}/`,
      bodyParams || {},
    );
  }
}

import {Injectable} from '@angular/core';
import {IntegrationsService} from '@generated/controllers/Integrations';
import {SharedService} from '@generated/controllers/Shared';
import {Advisor} from '@generated/defs/Advisor';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {MeetingGetResult} from '@generated/defs/MeetingGetResult';
import {getGetAdvisorStateSelector} from '@generated/store/integrations/getAdvisor/states/reducers';
import {LoginService} from '@lib/services';
import {select, Store} from '@ngrx/store';
import {
  AdvisorProposalState,
  Asset,
  assetNames,
  AssetType,
  ClientProposalState,
} from '@shared/analysis/models/asset';
import {MovableRisks, Risk} from '@shared/analysis/models/risks';
import {
  Kapp,
  KappContractObject,
  KappProductCategory1,
  KappProductCategory2,
  KappProposalItem,
  KappProposalItemType,
  KappRequirement,
} from '@shared/business-case/kapitol-portal-components/kapp.model';
import {LifeSituation} from '@shared/business-case/store';
import {map, take} from 'rxjs/operators';
import {VehicleObjective} from 'src/app/modules/financial-plan/store';
import {selectVehicleObjectives} from 'src/app/modules/financial-plan/store/vehicle-objective.selectors';
import {State} from 'src/store';

@Injectable()
export class PortalBusinessCaseService {
  constructor(
    private store: Store<State>,
    private sharedService: SharedService,
    private integrationsService: IntegrationsService,
    private loginService: LoginService,
  ) {}

  /**
   * Creates a business case in the Portal. Returns ID of the created business case.
   */
  async submitVehicleBusinessCase(familyUuid: string, familyMember: FamilyMember): Promise<string> {
    const advisor = await this.store
      .pipe(
        select(getGetAdvisorStateSelector),
        take(1),
        map(state => state.data),
      )
      .toPromise();

    const objectives = await this.store
      .select(selectVehicleObjectives({filterSelectedAssets: true}))
      .pipe(take(1))
      .toPromise();

    const meetings = await this.sharedService
      .listMeetings({
        family_uuid: familyUuid,
        situation: LifeSituation.VehicleInsurance,
      })
      .toPromise();

    const businessCase = createVehicleBusinessCase(objectives, advisor, familyMember, meetings);
    const res = await this.integrationsService
      .submitBusinessCase({
        data: {
          division: this.loginService.advisorDivision,
          data: businessCase,
        },
      })
      .toPromise();

    return res.id;
  }
}

export function createVehicleBusinessCase(
  objectives: VehicleObjective[],
  advisor: Advisor,
  familyMember: FamilyMember,
  meetings: MeetingGetResult[],
): Kapp {
  return {
    advisorId: advisor.sugarUserId,
    clientId: familyMember.sugarUuid,
    representativeId: null,
    insuredPersonsIds: [familyMember.sugarUuid],
    requirements: objectives.map(objective =>
      createRequirement(familyMember, objective, meetings, [
        'NON_LIFE_INSURANCE',
        'VEHICLE_INSURANCE',
      ]),
    ),
  };
}

function createRequirement(
  familyMember: FamilyMember,
  objective: VehicleObjective,
  _meetings: MeetingGetResult[],
  productCategory: [KappProductCategory1, KappProductCategory2],
): KappRequirement {
  return {
    category1: productCategory[0],
    category2: productCategory[1],
    // TODO:
    appointments: [],
    contractObjects: [createContractObject(familyMember, objective, productCategory)],
  };
}

function createContractObject(
  familyMember: FamilyMember,
  objective: VehicleObjective,
  productCategory: [KappProductCategory1, KappProductCategory2],
): KappContractObject {
  const mtplRisk = getRisk(objective.requirementsAsset.risks, MovableRisks.CompulsoryInsurance);
  const cascoRisk = getRisk(objective.requirementsAsset.risks, MovableRisks.AccidentInsurance);
  const theftRisk = getRisk(objective.requirementsAsset.risks, MovableRisks.Theft);
  const vandalismRisk = getRisk(objective.requirementsAsset.risks, MovableRisks.Vandalism);
  const naturalElementRisk = getRisk(
    objective.requirementsAsset.risks,
    MovableRisks.NaturalDisasters,
  );
  const windshieldRisk = getRisk(objective.requirementsAsset.risks, MovableRisks.Windshield);
  const otherRisk = getRisk(objective.requirementsAsset.risks, MovableRisks.Other);

  const items = createProposalItems(objective, productCategory);

  // true if a client did not accept advisor's proposal
  const isRefusedByClientWithSupplementaryProposal =
    items.some(item => !item.chosenByClient) && items.some(item => item.chosenByClient);

  return {
    insuranceObjectId: objective.propertyAsset.coreUuid,
    insuranceObjectType: 'VEHICLE',
    consultedContractIds: objective.advisorProposedAssets
      .map(asset => ('sugarUuid' in asset ? asset.sugarUuid : undefined))
      .filter(Boolean),
    option: {
      optionType: objective.requirementsAsset.requirementType,
      contractPeriod: objective.requirementsAsset.contractPeriod ?? null,
      contractPeriodCustom: objective.requirementsAsset.contractPeriodCustom ?? undefined,
      personContractOptions: [
        {
          personId: familyMember.sugarUuid,
          addNote: !!objective.requirementsAsset.note,
          note: objective.requirementsAsset.note ?? undefined,
          // TODO: v Kappce othernote nemame, pouzivame note
          // optionTypeOtherNote: '',
          advisorRecommendation: false,
          mtpl: mtplRisk.active,
          mtplLimit: mtplRisk.limit,
          casco: cascoRisk.active,
          cascoLimit: cascoRisk.limit,
          theft: theftRisk.active,
          theftLimit: theftRisk.limit,
          vandalism: vandalismRisk.active,
          vandalismLimit: vandalismRisk.limit,
          naturalElement: naturalElementRisk.active,
          naturalElementLimit: naturalElementRisk.limit,
          windshield: windshieldRisk.active,
          windshieldLimit: windshieldRisk.limit,
          // TODO: animalCollision v kappce nemame
          animalCollision: false,
          // animalCollisionLimit: animalCollisionRisk.limit,
          other: otherRisk.active,
          otherLimit: otherRisk.limit,
          // TODO: v kappce text pro jine nemame, pouzivame k tomu note
          otherDetails: '',
        },
      ],
      additionalOptions: {},
    },
    proposal: {
      isRefusedByClientWithSupplementaryProposal,
      hasClientProposedCustomSolution: false,
      hasClientProposedCustomSolutionNote: '',
      items,
    },
  };
}

function createProposalItems(
  objective: VehicleObjective,
  productCategory: [KappProductCategory1, KappProductCategory2],
): KappProposalItem[] {
  const items: KappProposalItem[] = [];

  for (const clientProposedAsset of objective.clientProposedAssets) {
    const advisorProposedAsset = objective.advisorProposedAssets.find(
      a => a.assetUuid === clientProposedAsset.originalAssetUuid,
    );

    let consultedContractId: string | null = null;
    if ('sugarUuid' in clientProposedAsset) {
      consultedContractId = clientProposedAsset.sugarUuid;
    }
    if (advisorProposedAsset && 'sugarUuid' in advisorProposedAsset) {
      consultedContractId = advisorProposedAsset.sugarUuid;
    }

    let hasSupplementaryProposal = false;

    // if the client didn't accept the proposal, add extra entry for the advisor proposal
    if (
      !hasClientAcceptedProposal(
        advisorProposedAsset?.advisorProposalState || clientProposedAsset.advisorProposalState,
        clientProposedAsset.clientProposalState,
      )
    ) {
      if (clientProposedAsset.originalAssetUuid) {
        // when changing, find the related advisor proposal by originalAssetUuid
        if (!advisorProposedAsset) {
          throw new Error(
            `Did not find proposal that client is rejecting: ${clientProposedAsset.originalAssetUuid}`,
          );
        }
        items.push(
          createProposalItem(advisorProposedAsset, productCategory, {
            chosenByClient: false,
            consultedContractId,
          }),
        );
        hasSupplementaryProposal = true;
      } else {
        // when terminating, the advisorProposedAsset and clientProposedAsset are the same thing
        items.push(
          createProposalItem(clientProposedAsset, productCategory, {
            chosenByClient: false,
            consultedContractId,
          }),
        );
        hasSupplementaryProposal = true;
      }
    }

    if (
      clientProposedAsset.advisorProposalState === AdvisorProposalState.New &&
      clientProposedAsset.clientProposalState === ClientProposalState.NewClientRejected
    ) {
      // client rejected the advisor's proposal to create a new contract; there is no other action possible
      // for this contract
      continue;
    }

    // Add entry for the asset accepted by the client
    items.push(
      createProposalItem(clientProposedAsset, productCategory, {
        chosenByClient: true,
        supplementaryProposal: hasSupplementaryProposal,
        consultedContractId,
      }),
    );
  }

  return items;
}

function createProposalItem(
  proposalAsset: Asset,
  productCategory: [KappProductCategory1, KappProductCategory2],
  {
    chosenByClient,
    supplementaryProposal,
    consultedContractId,
  }: {chosenByClient: boolean; supplementaryProposal?: boolean; consultedContractId?: string},
): KappProposalItem {
  if (proposalAsset.type !== AssetType.VehicleInsurance) {
    throw new Error(`Unexpected asset type for vehicle insurance: '${proposalAsset.type}'`);
  }

  const proposalType = chosenByClient
    ? getProposalTypeClient(proposalAsset.clientProposalState)
    : getProposalTypeAdvisor(proposalAsset.advisorProposalState);

  return {
    proposalType,
    consultedContractId: consultedContractId ?? null,
    newContractParams:
      proposalType === 'NEW'
        ? {
            // TODO: should be name of the product according to https://tporadce1.kapitol.cz/api/v1/swagger-ui/index.html?urls.primaryName=pgrest#/pgrest-tag/post_pgrest_epGetProducts
            name: proposalAsset.name || assetNames[proposalAsset.type],
            productCategory1: productCategory[0],
            productCategory2: productCategory[1],
            partner: proposalAsset.partnerId,
            product: proposalAsset.productId,
          }
        : null,
    contractParams: [
      {
        contractChangeType: 'NONE',
      },
    ],
    documents: {},
    justification: proposalAsset.justification,
    justificationAnswers: {
      answerCodes: [],
      answerOther: false,
      answerOtherText: null,
    },
    additionalInfo: null,
    chosenByClient,
    supplementaryProposal: supplementaryProposal ?? false,
    proposedByClient: false,
  };
}

function getRisk(risks: Risk[], key: MovableRisks): {active: boolean; limit?: number} {
  const risk = risks.find(r => r.key === key);

  if (!risk) {
    return {active: false};
  }

  const limit = risk.unit === 'million' ? risk.limit * 1_000_000 : risk.limit;
  return {
    active: risk.active,
    limit: risk.active ? limit : undefined,
  };
}

function getProposalTypeAdvisor(advisorProposalState: AdvisorProposalState): KappProposalItemType {
  switch (advisorProposalState) {
    case AdvisorProposalState.New:
      return 'NEW';
    case AdvisorProposalState.Updated:
      return 'MODIFY';
    case AdvisorProposalState.Terminated:
      return 'CANCEL';
    case AdvisorProposalState.Unchanged:
      return 'KEEP';
    default:
      throw new Error(`Unexpected advisor proposal state: ${advisorProposalState}`);
  }
}

function getProposalTypeClient(clientProposalState: ClientProposalState): KappProposalItemType {
  switch (clientProposalState) {
    case ClientProposalState.NewClientApproved:
    case ClientProposalState.NewClientUpdated:
      return 'NEW';
    case ClientProposalState.ClientUpdated:
    case ClientProposalState.AdvisorUpdated:
      return 'MODIFY';
    case ClientProposalState.NewClientRejected:
    case ClientProposalState.Unchanged:
      return 'KEEP';
    case ClientProposalState.ClientTerminated:
    case ClientProposalState.AdvisorTerminated:
      return 'CANCEL';
    default:
      throw new Error(`Unexpected advisor proposal state: ${clientProposalState}`);
  }
}

function hasClientAcceptedProposal(
  advisorProposalState: AdvisorProposalState,
  clientProposalState: ClientProposalState,
): boolean {
  switch (clientProposalState) {
    case ClientProposalState.NewClientApproved:
    case ClientProposalState.AdvisorUpdated:
    case ClientProposalState.AdvisorTerminated:
      return true;
    default:
    // do nothing
  }

  if (
    advisorProposalState === AdvisorProposalState.Unchanged &&
    clientProposalState === ClientProposalState.Unchanged
  ) {
    return true;
  }

  return false;
}

import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {numberMask} from '@lib/utils/imask.utils';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'kpt-simple-asset-value-input',
  templateUrl: './simple-asset-value-input.component.html',
  styleUrls: ['./simple-asset-value-input.component.scss'],
})
export class SimpleAssetValueInputComponent implements OnInit, OnChanges {
  mask = numberMask(false, 0);

  formControl = new UntypedFormControl();
  @Input() assetValueFormControl: UntypedFormControl;
  @Input() id: string;
  @Input() appendText: string;
  @Input() max: number = null;
  @Input() min: number = null;
  @Input() customFormGroupClass: string;
  @Input() readonly = false;
  @Input() showErrorsOn: boolean | 'touched' = 'touched';
  @Input() customErrors: {[key: string]: string} | string = null; // extra or different error msgs
  @Output() blur = new EventEmitter();
  @Output() focus = new EventEmitter();
  @Output() valueChange = new EventEmitter();

  private isOriginalValueNull = false;
  private removedAsset1stCheck = false; // valueChange should not emit value if asset is removed
  private removedAsset2ndCheck = false; // this prevents setting removedAsset1stCheck to true on deleting other assets

  ngOnInit() {
    this.formControl.setValue(this.assetValueFormControl.value);
    this.isOriginalValueNull = this.assetValueFormControl.value === null;
    this.formControl.setValidators(this.assetValueFormControl.validator);
    this.formControl.setAsyncValidators(this.assetValueFormControl.asyncValidator);
    this.formControl.valueChanges.pipe(untilDestroyed(this)).subscribe(v => {
      if (this.removedAsset1stCheck) this.removedAsset1stCheck = false;
      else {
        this.valueChange.emit(v);
        this.removedAsset2ndCheck = false;
      }
    });
  }

  ngOnChanges() {
    if (this.formControl.value !== this.assetValueFormControl.value) {
      if (
        this.assetValueFormControl.value === null &&
        !this.removedAsset1stCheck &&
        !this.removedAsset2ndCheck
      ) {
        this.removedAsset1stCheck = true;
        this.removedAsset2ndCheck = true;
      }
      this.formControl.setValue(this.assetValueFormControl.value, {emitEvent: false});
    }
  }

  onInputBlur(value: any) {
    if (!this.isOriginalValueNull && value === '') this.formControl.setValue(0);
    else if (value !== null || value !== '') this.isOriginalValueNull = false;
    this.blur.emit(value);
  }

  onInputFocus(event: any) {
    if (event.target.value === '0') {
      this.formControl.setValue(null);
    } else if (event.target.value === '') this.isOriginalValueNull = true;
    this.focus.emit(event);
  }
}

/* tslint:disable:max-line-length */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * 127.0.0.1:8000/api
 */

import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {of} from 'rxjs';

import {catchError, map, switchMap} from 'rxjs/operators';
import {IntegrationsService} from '../../../../controllers/Integrations';
import * as actions from './actions';

@Injectable()
export class DeleteFilesEffects {
  DeleteFiles = createEffect(() =>
    this.storeActions.pipe(
      ofType<actions.Start>(actions.Actions.START),
      switchMap((action: actions.Start) =>
        this.integrationsService.deleteFiles(action.payload).pipe(
          map(result => new actions.Success(result)),
          catchError((error: HttpErrorResponse) => of(new actions.Error(error))),
        ),
      ),
    ),
  );

  constructor(private storeActions: Actions, private integrationsService: IntegrationsService) {}
}

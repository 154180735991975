import {Injectable} from '@angular/core';
import {FamilyMember} from '@generated/defs/FamilyMember';
import {Store} from '@ngrx/store';
import {BaseAssetType} from '@shared/analysis/asset-types/common/base-asset-type';
import {AssetDefinition} from '@shared/analysis/forms/definitions.models';
import {assetNames, AssetType} from '@shared/analysis/models/asset';
import {selectFamilyHead} from '@shared/analysis/operators';
import * as moment from 'moment';
import {State} from 'src/store';
import {ChildBirthAsset} from '../../../models/objectives';

@Injectable()
export class ChildBirthAssetType extends BaseAssetType {
  private familyHead: FamilyMember;

  constructor(private store: Store<State>) {
    super();

    this.store.pipe(selectFamilyHead()).subscribe(familyHead => {
      this.familyHead = familyHead;
    });
  }

  create(): ChildBirthAsset {
    return {
      type: AssetType.ChildBirth,
      assetUuid: this.createId(),
      start: moment().format('YYYY-MM-DD'),
      value: 0,
      name: null,
      description: null,
      maternityFamilyMemberUuid: this.familyHead?.sugarUuid,
      parentalFamilyMemberUuid: this.familyHead?.sugarUuid,
      parentalBenefitInMonths: 0,
    };
  }

  getAssetDefinition(): AssetDefinition {
    return {
      name: assetNames[AssetType.ChildBirth],
      type: AssetType.ChildBirth,
    };
  }
}

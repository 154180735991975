import {Injectable} from '@angular/core';
import {col, row} from '@lib/utils';
import {select, Store} from '@ngrx/store';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {BaseAssetType} from '@shared/analysis/asset-types/common/base-asset-type';
import {FinancialProductAssetType} from '@shared/analysis/asset-types/common/financial-product-asset-type';
import {DefaultValueService} from '@shared/analysis/default-value.service';
import {FieldLabel} from '@shared/analysis/field-label';
import {AbstractAssetsDefinitions} from '@shared/analysis/forms/abstract-assets.definitions';
import {AssetDefinition, FinancialProductTab} from '@shared/analysis/forms/definitions.models';
import {createProductTab} from '@shared/analysis/forms/forms.helpers';
import {assetNames, AssetType, RegularPaymentType} from '@shared/analysis/models/asset';
import {VehicleInsuranceAsset} from '@shared/analysis/models/insurance-products';
import {VehicleRequirementsAsset} from '@shared/analysis/models/objectives';
import {MovableRisks} from '@shared/analysis/models/risks';
import {selectAllAssets} from '@shared/analysis/store';
import {getAssetValidators} from '@shared/analysis/validations.helpers';
import {FormlyTypes} from '@shared/ui/formly/formly.enums';
import {
  createLabelField,
  createNamedGroup,
  createTabs,
  getBasicField,
} from '@shared/ui/formly/formly.utils';
import {map, take} from 'rxjs/operators';
import {State} from 'src/store';

@Injectable()
export class VehicleInsuranceAssetType extends BaseAssetType {
  constructor(
    private store: Store<State>,
    private defaultValueService: DefaultValueService,
    private abstractAssets: AbstractAssetsDefinitions,
    private financialProductAssetType: FinancialProductAssetType,
  ) {
    super();
  }

  async create(
    opts: Pick<
      VehicleInsuranceAsset,
      | 'relatedObjectiveUuid'
      | 'relatedPropertyUuid'
      | 'stakeholderUuid'
      | 'proposal'
      | 'advisorProposalState'
    >,
  ): Promise<Partial<VehicleInsuranceAsset>> {
    const asset: Partial<VehicleInsuranceAsset> = {
      ...(this.defaultValueService.getInsurance() as Partial<VehicleInsuranceAsset>),
      type: AssetType.VehicleInsurance,
      assetUuid: this.createId(),
      relatedObjectiveUuid: opts.relatedObjectiveUuid,
      relatedPropertyUuid: opts.relatedPropertyUuid,
      stakeholderUuid: opts.stakeholderUuid,
      proposal: opts.proposal,
      advisorProposalState: opts.advisorProposalState,
      vehicleAccidentInsurance: {},
      vehicleSupplementaryInsurance: {},
      otherRisks: [],
      frequency: RegularPaymentType.Year,
    };

    // There are three ways how VehicleInsuranceAsset can be created:
    //
    // 1. Proposal is created
    // 2. Existing contract is created
    // 3. Existing contract is synchronized from Core
    //
    // If 1, then prefill the contract limits as specified in the requirements asset
    if (opts.proposal && opts.relatedPropertyUuid) {
      const requirements = await this.store
        .pipe(
          select(selectAllAssets),
          map(
            assets =>
              assets.find(
                a =>
                  a.type === AssetType.VehicleRequirements &&
                  a.relatedPropertyUuid === opts.relatedPropertyUuid,
              ) as VehicleRequirementsAsset,
          ),
          take(1),
        )
        .toPromise();

      for (const risk of requirements.risks) {
        if (risk.active && risk.limit) {
          switch (risk.key) {
            case MovableRisks.CompulsoryInsurance:
              asset.compulsoryInsuranceHealthInMil = risk.limit;
              asset.compulsoryInsurancePropertyInMil = risk.limit;
              break;
            case MovableRisks.AccidentInsurance:
              asset.accidentInsuranceTotal = risk.limit;
              break;
            case MovableRisks.Vandalism:
            case MovableRisks.NaturalDisasters:
            case MovableRisks.Windshield:
            case MovableRisks.Theft:
              asset.vehicleSupplementaryInsurance[risk.key] = risk.limit;
              break;
            default:
              console.warn('Cannot prefill limit for risk', risk.key);
          }
        }
      }
    }

    return asset;
  }

  getAssetDefinition(): AssetDefinition {
    const type = AssetType.VehicleInsurance;
    const tabs = createTabs(
      createProductTab(
        FinancialProductTab.Basic,
        this.abstractAssets.getProductGroup(type),
        this.abstractAssets.getCommonFinancialProductGroup('proposalFields'),
        this.abstractAssets.commonFinancialProduct.name,
        row([
          col(this.abstractAssets.commonFinancialProduct.contractNumber),
          col(this.abstractAssets.getStakeholderField()),
        ]),
        row([
          col(this.abstractAssets.getRelatedObjectiveField(type)),
          col(this.abstractAssets.getRelatedPropertyField(type)),
        ]),
        this.abstractAssets.getCommonFinancialProductGroup('payment'),
        this.abstractAssets.getCommonFinancialProductGroup('period'),
        row([
          col(
            getBasicField(
              FormlyTypes.Currency,
              'compulsoryInsuranceHealthInMil',
              FieldLabel.COMPULSORY_INSURANCE_HEALTH,
              {appendText: 'mil. Kč'},
            ),
          ),
          col(
            getBasicField(
              FormlyTypes.Currency,
              'compulsoryInsurancePropertyInMil',
              FieldLabel.COMPULSORY_INSURANCE_PROPERTY,
              {appendText: 'mil. Kč'},
            ),
          ),
        ]),
        getBasicField(
          FormlyTypes.Currency,
          'accidentInsuranceTotal',
          FieldLabel.ACCIDENT_INSURANCE_TOTAL,
        ),
        this.abstractAssets.getCommonFinancialProductGroup('attachments'),
        this.abstractAssets.getJustificationGroup(),
      ),

      createProductTab(
        FinancialProductTab.Extra,
        createNamedGroup(
          'Detaily smlouvy',
          createNamedGroup(
            'Role osoby na smlouvě',
            row([col(createLabelField('Role osoby na smlouvě'))]),
            row([
              col(getBasicField(FormlyTypes.Checkbox, 'policyholder', FieldLabel.POLICYHOLDER)),
              col(getBasicField(FormlyTypes.Checkbox, 'holder', FieldLabel.HOLDER)),
              col(getBasicField(FormlyTypes.Checkbox, 'owner', FieldLabel.OWNER)),
            ]),
          ),
          this.vehicleSupplementaryInsuranceFields(),
          this.abstractAssets.otherRisks,
          ...this.financialProductAssetType.commonInsuranceParticipationFields(),
          this.vehicleAccidentInsuranceFields(),
        ),
      ),

      createProductTab(
        FinancialProductTab.PaymentInfo,
        this.abstractAssets.getCommonFinancialProductGroup('paymentInfo'),
      ),

      this.abstractAssets.getCoreUuidField(),
    );

    return {
      name: assetNames[type],
      type,
      fields: [tabs],
      validators: getAssetValidators(type),
      model: {},
    };
  }

  private vehicleSupplementaryInsuranceFields(): FormlyFieldConfig {
    return {
      key: 'vehicleSupplementaryInsurance',
      fieldGroup: [
        row([
          col(getBasicField(FormlyTypes.Currency, 'windshield', FieldLabel.WINDSHIELD)),
          col(getBasicField(FormlyTypes.Currency, 'allWindows', FieldLabel.ALL_WINDOWS)),
          col(getBasicField(FormlyTypes.Currency, 'baggage', FieldLabel.BAGGAGE)),
        ]),
        row([
          col(
            getBasicField(
              FormlyTypes.Currency,
              'accidentAssistance',
              FieldLabel.ACCIDENT_ASSISTANCE,
            ),
          ),
          col(getBasicField(FormlyTypes.Currency, 'legalProtection', FieldLabel.LEGAL_PROTECTION)),
          col(getBasicField(FormlyTypes.Currency, 'animalDamage', FieldLabel.ANIMAL_DAMAGE)),
        ]),
        row([
          col(getBasicField(FormlyTypes.Currency, 'vandalism', FieldLabel.VANDALISM)),
          col(
            getBasicField(FormlyTypes.Currency, 'naturalDisasters', FieldLabel.NATURAL_DISASTERS),
          ),
          col(
            getBasicField(
              FormlyTypes.Currency,
              'unintentionalAccidentInsurance',
              FieldLabel.UNINTENTIONAL_ACCIDENT_INSURANCE,
            ),
          ),
        ]),
        row([
          col([
            getBasicField(
              FormlyTypes.Currency,
              'replacementVehicle',
              FieldLabel.REPLACEMENT_VEHICLE,
            ),
          ]),
          col([
            getBasicField(
              FormlyTypes.Currency,
              'nonStandardEquipment',
              FieldLabel.NON_STANDARD_EQUIPMENT,
            ),
          ]),
          col([getBasicField(FormlyTypes.Currency, 'theft', FieldLabel.THEFT)]),
        ]),
        // See comment in insurance-product.ts
        /*otherInsurance: [{
          label: string;
          value: number;
        }];*/
      ],
    };
  }

  private vehicleAccidentInsuranceFields(): FormlyFieldConfig {
    return {
      key: 'vehicleAccidentInsurance',
      fieldGroup: [
        row([
          col(getBasicField(FormlyTypes.Checkbox, 'alarm', FieldLabel.ALARM)),
          col(getBasicField(FormlyTypes.Checkbox, 'immobilizer', FieldLabel.IMMOBILIZER)),
          col(
            getBasicField(
              FormlyTypes.Checkbox,
              'mechanicalSecurity',
              FieldLabel.MECHANICAL_SECURITY,
            ),
          ),
        ]),
        row([
          col(
            getBasicField(
              FormlyTypes.Checkbox,
              'activeSearchSystem',
              FieldLabel.ACTIVE_SEARCH_SYSTEM,
            ),
          ),
          col(getBasicField(FormlyTypes.Checkbox, 'vinOnGlass', FieldLabel.VIN_ON_GLASS)),
          col([]),
        ]),
      ],
    };
  }
}

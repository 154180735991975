import {IStepperProgressItem} from '@shared/ui';
import {createStepperProgressConfig} from '@shared/ui/stepper-progress/stepper-progress.utils';
import {SecuredPage} from 'src/app/layouts/secured';
import {ClientPage} from 'src/app/modules/client';
import {BusinessCaseStatus} from 'src/app/modules/financial-plan/objectives/objectives.models';

export enum VehicleInsurancePage {
  Family = 'family',
  Vehicle = 'vehicle',
  Requirements = 'requirements',
  CurrentSituation = 'current-situation',
  AnalysisSummary = 'analysis-summary',
  AdvisorProposed = 'advisor-proposed',
  ClientProposed = 'client-proposed',
  PlanSummary = 'plan-summary',
  Finalize = 'finalize',
}

export const vehicleInsurancePageNames = {
  [VehicleInsurancePage.Family]: 'Rodina',
  [VehicleInsurancePage.Vehicle]: 'Vozidlo',
  [VehicleInsurancePage.Requirements]: 'Požadavky',
  [VehicleInsurancePage.CurrentSituation]: 'Současné řešení',
  [VehicleInsurancePage.AnalysisSummary]: 'Shrnutí',
  [VehicleInsurancePage.AdvisorProposed]: 'Návrh řešení',
  [VehicleInsurancePage.ClientProposed]: 'Volba klienta',
  [VehicleInsurancePage.PlanSummary]: 'Shrnutí',
  [VehicleInsurancePage.Finalize]: 'Odeslání',
};

export function vehicleInsuranceStepperProgressConfig(
  businessCaseStatus: BusinessCaseStatus,
): IStepperProgressItem[] {
  if (!businessCaseStatus) return [];

  const steps: Record<BusinessCaseStatus, VehicleInsurancePage[]> = {
    [BusinessCaseStatus.FinancialAnalysisPreparation]: [
      VehicleInsurancePage.Family,
      VehicleInsurancePage.Vehicle,
      VehicleInsurancePage.Requirements,
      VehicleInsurancePage.CurrentSituation,
      VehicleInsurancePage.AnalysisSummary,
    ],
    [BusinessCaseStatus.FinancialPlanPreparation]: [
      VehicleInsurancePage.AdvisorProposed,
      VehicleInsurancePage.PlanSummary,
    ],
    [BusinessCaseStatus.FinancialPlanPresentation]: [
      VehicleInsurancePage.Requirements,
      VehicleInsurancePage.ClientProposed,
      VehicleInsurancePage.PlanSummary,
    ],
    [BusinessCaseStatus.FinancialPlanFinalisation]: [VehicleInsurancePage.Finalize],
    [BusinessCaseStatus.FinancialPlanTermination]: [],
  };

  const stepperItems: IStepperProgressItem[] = steps[businessCaseStatus]?.map(url => ({
    url,
    label: vehicleInsurancePageNames[url],
  }));

  return createStepperProgressConfig(stepperItems, SecuredPage.Client, ClientPage.VehicleInsurance);
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {PortalBusinessCaseService} from '@shared/business-case/portal-business-case.service';
import {FamilyModule} from '@shared/services/family/family.module';
import {AlertModule} from '@shared/ui';
import {
  reducer as financialPlanReducer,
  storeFeatureName as financialPlanStoreFeatureName,
} from '../../app/modules/financial-plan/store';
import {MissingEmailAlertComponent} from './missing-email-alert/missing-email-alert.component';
import {reducer, storeFeatureName} from './store';

@NgModule({
  imports: [
    AlertModule,
    CommonModule,
    FamilyModule,
    StoreModule.forFeature(storeFeatureName, reducer),
    StoreModule.forFeature(financialPlanStoreFeatureName, financialPlanReducer),
  ],
  providers: [PortalBusinessCaseService],
  declarations: [MissingEmailAlertComponent],
  exports: [MissingEmailAlertComponent],
})
export class BusinessCaseModule {}

/* tslint:disable:max-line-length */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * 127.0.0.1:8000/api
 */

import {NgModule} from '@angular/core';
import {EffectsModule as NgrxEffectsModule} from '@ngrx/effects';
import {StoreModule as NgrxStoreModule} from '@ngrx/store';

import {IntegrationsService} from '../../../controllers/Integrations';
import {FormsSharedModule} from '../../forms-shared.module';
import {GetAdvisorPortalFormService} from './getAdvisorPortal.service';

import {GetAdvisorPortalEffects} from './states/effects';
import {GetAdvisorPortalReducer} from './states/reducers';
import {selectorName} from './states/reducers';

@NgModule({
  imports: [
    FormsSharedModule,
    NgrxStoreModule.forFeature(selectorName, GetAdvisorPortalReducer),
    NgrxEffectsModule.forFeature([GetAdvisorPortalEffects]),
  ],
  providers: [IntegrationsService, GetAdvisorPortalFormService],
})
export class GetAdvisorPortalModule {}

/* tslint:disable:max-line-length */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * 127.0.0.1:8000/api
 */

import {createFeatureSelector} from '@ngrx/store';

import {HttpErrorResponse} from '@angular/common/http';
import * as __model from '../../../../model';
import * as actions from './actions';

export interface ConsumerState {
  data: __model.CalculateConsumer[] | null;
  loading: boolean;
  error: HttpErrorResponse | null;
}

export const initialConsumerState: ConsumerState = {
  data: null,
  loading: false,
  error: null,
};

export const selectorName = 'Consumer_Consumer';
export const getConsumerStateSelector = createFeatureSelector<ConsumerState>(selectorName);

export function ConsumerReducer(
  state: ConsumerState = initialConsumerState,
  action: actions.ConsumerAction,
): ConsumerState {
  switch (action.type) {
    case actions.Actions.START:
      return {...state, loading: true, error: null};
    case actions.Actions.SUCCESS:
      return {...state, data: action.payload, loading: false};
    case actions.Actions.ERROR:
      return {...state, error: action.payload, loading: false};
    default:
      return state;
  }
}

/* tslint:disable:max-line-length */
/**
 * Mortgages API
 * devs@swehq.com
 * v1
 * 127.0.0.1:8000/api
 */

import {Injectable} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {UiService} from '../../../controllers/Ui';

@Injectable()
export class CreateOrUpdateAdvisorUiSettingsFormService {
  form: UntypedFormGroup;
  constructor(private uiService: UiService) {
    this.form = new UntypedFormGroup({
      data: new UntypedFormGroup(
        {
          intro: new UntypedFormGroup(
            {
              financialAnalysisOverview: new UntypedFormControl(false, []),
              simpleFinancialAnalysisOverview: new UntypedFormControl(false, []),
              homepageOverview: new UntypedFormControl(false, []),
              clientPageOverview: new UntypedFormControl(false, []),
              financialPlanOverview: new UntypedFormControl(false, []),
            },
            [Validators.required],
          ),
        },
        [Validators.required],
      ),
    });
  }

  submit(raw = false) {
    const data = raw ? this.form.getRawValue() : this.form.value;
    return this.uiService.createOrUpdateAdvisorUiSettings(data);
  }
}
